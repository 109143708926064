module.exports = [{
      plugin: require('../../../node_modules/.pnpm/gatsby-plugin-nprogress@5.14.0_gatsby@5.14.1_babel-eslint@10.1.0_eslint@7.32.0__react-dom@18._q46wmx3nq4yxcwytm7o5l6qq5i/node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"color":"#ff9800","showSpinner":false},
    },{
      plugin: require('../../../node_modules/.pnpm/gatsby-plugin-google-gtag@5.14.0_gatsby@5.14.1_babel-eslint@10.1.0_eslint@7.32.0__react-dom@1_xglbmoaw6nuowonndmzhnl6cii/node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-FN9XE9YPJ7"],"gtagConfig":{}},
    },{
      plugin: require('../../../node_modules/.pnpm/gatsby@5.14.1_babel-eslint@10.1.0_eslint@7.32.0__react-dom@18.3.1_react@18.3.1__react@18.3.1__c3z3b6peyj3uoul2myzvxxl7oi/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
